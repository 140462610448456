<template>
  <bar :chart-data="chartData" :chart-options="options">
  </bar>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'StackedBarChart',
  components: {
    Bar
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        aspectRatio: 2.5,
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      }
    }
  },
  computed: {
    chartData() {
      const { labels, datasets } = this.data

      // const hueStep = 360 / (datasets.length / 4)
      // const colouredDatasets = datasets.map((dataset, i) => {
      //   return {
      //     ...dataset,
      //     backgroundColor: `hsl(${i * hueStep % 360}deg, 50%, 60%)`
      //   }
      // })

      return {
        labels,
        datasets
      }
    }
  }
}
</script>
