<template>
  <v-expansion-panel class="overheads-panel">
    <v-expansion-panel-header>
      <span class="text-h5">Overhead hours</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row dense>
        <v-spacer></v-spacer>
        <v-btn text @click="selectJobs('all')">All</v-btn>
        <v-btn text @click="selectJobs('none')">None</v-btn>
      </v-row>
      <v-row dense>
          <v-select v-model="selectedJobs" label="Overhead jobs" multiple
                    :items="jobsList">
          </v-select>
      </v-row>

      <stacked-bar-chart v-if="chartData" :data="chartData"></stacked-bar-chart>
      <v-data-table :headers="tableHeaders" :items="tableData"
                    dense disable-pagination hide-default-footer
                    :loading="tableData.length === 0">
        <template v-slot:body.append>
          <tr class="total">
            <td v-for="(total, i) of totals" :key="`${tableHeaders[i].value}-${total}`">
              {{ total }}
            </td>
          </tr>
        </template>
      </v-data-table>

    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import * as api from '../../api'
import StackedBarChart from './StackedBarChart'
import { analysisMixin } from '../../mixins/AnalysisMixin'

export default {
  name: 'OverheadsPanel',
  components: { StackedBarChart },
  mixins: [analysisMixin],
  data() {
    return {
      overheads: {},
      selectedJobs: []
    }
  },
  watch: {
    dateRange() {
      this.refresh()
    }
  },
  computed: {
    filteredUsers() {
      const { users } = this.overheads
      if (this.employmentStatus) {
        return users.filter(user => user.status === this.employmentStatus)
      }
      return users || []
    },
    chartData() {
      if (!this.overheads.jobs) {
        return null
      }

      const { jobs } = this.overheads
      const filteredUsers = this.filteredUsers
      const filteredJobs = Object.keys(jobs).filter(job => this.selectedJobs.includes(job))

      const datasets = filteredJobs.map((jobName) => {
        return {
          label: jobName,
          data: filteredUsers.map(user => user.jobs[jobName]),
          backgroundColor: jobs[jobName]
        }
      })

      return {
        labels: filteredUsers.map(user => user.name),
        datasets
      }
    },
    jobsList() {
      const { jobs } = this.overheads
      if (jobs) {
        return Object.keys(jobs)
      }
      return []
    },
    tableHeaders() {
      const keys = ['Name', ...this.selectedJobs]
      return keys.map(value => ({ text: value, value }))
    },
    tableData() {
      return this.filteredUsers.map(user => {
        const row = { ...user.jobs }
        row.Name = user.name
        return row
      })
    },
    totals() {
      const headers = this.tableHeaders.map(h => h.value)
      const totals = Array(headers.length).fill(0)
      for (const row of this.tableData) {
        headers.forEach((k, i) => {
          if (i > 0) {
            totals[i] += row[k]
          }
        })
      }
      totals[0] = 'Total'
      return totals
    }
  },
  methods: {
    async refresh() {
      const { start, end } = this.dateRange
      this.overheads = await api.get('/user/overheads', { start, end })

      if (this.selectedJobs.length === 0) {
        this.selectedJobs = this.selectJobs('all')
      }
    },
    tableSorted(column) {
      // if (column !== 'user_name') {
      //   this.chartColumn = column
      // }
    },
    selectJobs(a) {
      if (a === 'all') {
        this.selectedJobs = this.jobsList
      } else if (a === 'none') {
        this.selectedJobs = []
      }
      return this.selectedJobs
    }
  },
  async mounted() {
    await this.refresh()
  }
}
</script>

<style lang="scss">
.overheads-panel {
  .v-select__selections {
    font-size: 0.8rem;
    * {
      margin-bottom: 0;
    }
  }
}

tr.total {
  font-weight: bold;
}
</style>
