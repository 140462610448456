<template>
  <v-select :value="value"
            :items="userStatusOptions" label="Employment status"
            @change="$emit('input', $event); $emit('change')">
  </v-select>
</template>

<script>
import { userOptions } from '../../data'
import { makeTitle } from '../../util'

export default {
  name: 'EmploymentStatusSelect',
  props: {
    value: String
  },
  computed: {
    userStatusOptions() {
      const all = [{ value: null, text: 'All' }]
      const options = userOptions.status.map(status => ({ value: status, text: makeTitle(status) }))
      return all.concat(options)
    }
  }
}
</script>
