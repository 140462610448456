<template>
  <div class="mt-3 buttons">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'RightHandButtons'
}
</script>

<style scoped lang="scss">
div.buttons {
  button {
    margin: 5px;
  }
}
</style>
