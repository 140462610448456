<template>
  <v-btn :color="color" depressed :disabled="disabled" :x-small="small" @click="click">
    <slot></slot>
    <a :href="url" class="d-none" ref="link"></a>
  </v-btn>
</template>

<script>
import { createURL } from '../../api'

export default {
  name: 'FileDownload',
  props: {
    endPoint: String,
    params: {
      type: Object,
      default: () => ({})
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    url() {
      const { api_key } = this.$store.state.user
      const params = { api_key, ...this.params }
      return createURL(this.endPoint, params)
    }
  },
  methods: {
    click() {
      this.$refs.link.click()
      setTimeout(() => this.$emit('click'), 2000)
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
