<template>
  <bar :chart-data="chartData" :chart-options="options">
  </bar>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: { responsive: true, aspectRatio: 2.5 }
    }
  },
  computed: {
    chartData() {
      const { label, dataset, colour } = this.data
      const entries = Object
        .entries(dataset)
        .sort((a, b) => a[0].localeCompare(b[0]))

      const labels = entries.map(e => e[0])
      const data = entries.map(e => e[1])

      return {
        labels,
        datasets: [{
          label,
          data,
          backgroundColor: colour
        }]
      }
    }
  }
}
</script>
