<template>
<page-wrapper>
  <header>
    <v-row>
      <v-col cols="2">
        <employment-status-select v-model="employmentStatus"></employment-status-select>
      </v-col>
      <v-col>
        <date-range @change="dateRangeChanged"></date-range>
      </v-col>
      <v-spacer></v-spacer>
      <right-hand-buttons v-if="validDateRange">
        <file-download end-point="/analysis/report" :params="dateRange">
          Report
        </file-download>
      </right-hand-buttons>
    </v-row>
  </header>
  <v-expansion-panels v-model="panels" multiple>
    <analysis-panel :employment-status="employmentStatus" :date-range="dateRange">
    </analysis-panel>
    <overheads-panel :employment-status="employmentStatus" :date-range="dateRange">
    </overheads-panel>
  </v-expansion-panels>
</page-wrapper>
</template>

<script>
import PageWrapper from '../components/ui/PageWrapper'
import { userOptions } from '../data'
import { makeTitle } from '../util'
import EmploymentStatusSelect from '../components/ui/EmploymentStatusSelect'
import DateRange from '../components/ui/DateRange'
import OverheadsPanel from '../components/analysis/OverheadsPanel'
import AnalysisPanel from '../components/analysis/AnalysisPanel'
import RightHandButtons from '../components/ui/RightHandButtons'
import FileDownload from '../components/export/FileDownload'

export default {
  name: 'Analysis',
  components: {
    FileDownload,
    RightHandButtons,
    AnalysisPanel,
    OverheadsPanel,
    DateRange,
    EmploymentStatusSelect,
    PageWrapper
  },
  data() {
    return {
      panels: [0, 1],
      employmentStatus: null,
      dateRange: { start: null, end: null }
    }
  },
  watch: {
    employmentStatus() {
      // console.log('e', this.employmentStatus)
      // console.log(this.$store.state.users)
    }
  },
  computed: {
    userStatusOptions() {
      const all = [{ value: null, text: 'All' }]
      const options = userOptions.status.map(status => ({ value: status, text: makeTitle(status) }))
      return all.concat(options)
    },
    validDateRange() {
      return this.dateRange.start !== null && this.dateRange.end !== null
    }
  },
  methods: {
    async dateRangeChanged({ start, end }) {
      this.dateRange = { start, end }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
