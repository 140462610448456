<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <span class="text-h5">General</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <bar-chart v-if="chartData" :data="chartData"></bar-chart>
      <v-data-table :headers="headers" :items="filteredReport"
                    dense disable-pagination hide-default-footer
                    :loading="report.length === 0" :sort-by="'no_jobs'" :sort-desc="true"
                    @update:sort-by="tableSorted">
        <template v-slot:body.append>
          <tr class="total">
            <td v-for="(total, i) in totals" :key="`${headers[i].value}-${total}`">
              {{ total }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import * as api from '@/api'
import { companyColours, userOptions } from '@/data'
import { makeTitle } from '@/util'
import BarChart from './BarChart'
import { analysisMixin } from '../../mixins/AnalysisMixin'

export default {
  name: 'AnalysisPanel',
  components: { BarChart },
  mixins: [analysisMixin],
  data() {
    return {
      report: [],
      chartColumn: 'no_jobs',
      headers: [
        { text: 'Name', value: 'user_name' },
        { text: 'Jobs', value: 'no_jobs' },
        { text: 'Shoot days', value: 'no_shoot_days' },
        // { text: 'Crew days', value: 'no_crew_days' },
        { text: 'Workshop hours', value: 'workshop_hours' },
        { text: 'Overhead hours', value: 'overhead_hours' },
        { text: 'Travel', value: 'travel_over_1hr' },
        { text: 'Car fee (£)', value: 'car_fees' },
        { text: 'Mileage fee (£)', value: 'mileage_fees' },
        { text: 'Addt. payment (£)', value: 'additional_payment' }
      ]
    }
  },
  watch: {
    dateRange() {
      this.refresh()
    }
  },
  computed: {
    filteredReport() {
      if (this.employmentStatus) {
        return this.report.filter(user => user.user_status === this.employmentStatus)
      }
      return this.report
    },
    userStatusOptions() {
      const all = [{ value: null, text: 'All' }]
      const options = userOptions.status.map(status => ({ value: status, text: makeTitle(status) }))
      return all.concat(options)
    },
    chartData() {
      if (!this.chartColumn) {
        return null
      }
      const dataset = {}
      for (const row of this.filteredReport) {
        dataset[row.user_name] = row[this.chartColumn]
      }

      const index = this.headers.findIndex((e) => e.value === this.chartColumn)
      const label = this.headers[index].text
      const colour = companyColours[index % companyColours.length]

      return { label, dataset, colour }
    },
    totals() {
      const headers = this.headers.map(h => h.value)
      const totals = Array(headers.length).fill(0)
      for (const row of this.filteredReport) {
        headers.forEach((k, i) => {
          totals[i] += row[k]
        })
      }
      const noJobsIndex = headers.indexOf('no_jobs')
      totals[0] = 'Total'
      totals[noJobsIndex] = '-'
      return totals
    }
  },
  methods: {
    async refresh() {
      const { start, end } = this.dateRange
      this.report = await api.get('/user/report', { start, end })
    },
    tableSorted(column) {
      if (column !== 'user_name') {
        this.chartColumn = column
      }
    }
  },
  async mounted() {
    await this.refresh()
  }
}
</script>

<style scoped>
tr.total {
  font-weight: bold;
}
</style>
