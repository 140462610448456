<template>
<div class="date-range">
  <v-select v-model="rangeChoice" :items="rangeChoices" label="Date range">
  </v-select>
  <template v-if="rangeChoice === 'two-dates'">
    <date-picker v-model="dates.start" label="Start" class="date-picker"></date-picker>
    <date-picker v-model="dates.end" label="End"></date-picker>
  </template>
  <template v-if="rangeChoice === 'month'">
    <v-select v-model="month" :items="monthChoices" label="Month">
    </v-select>
  </template>
  <template v-if="rangeChoice === 'quarter'">
    <v-select v-model="quarter" :items="quarterChoices" label="Quarter" class="quarter">
    </v-select>
  </template>
  <template v-if="hasYear">
    <v-select v-model="year" :items="yearChoices" label="Year"
              :class="{ year: this.rangeChoice === 'year' }">
    </v-select>
  </template>
</div>
</template>

<script>
import DatePicker from './DatePicker'
import { today } from '../../util'
import dayjs from 'dayjs'

export default {
  name: 'DateRange',
  components: { DatePicker },
  data() {
    return {
      rangeChoice: 'all',
      choices: ['all', 'two-dates', 'month', 'quarter', 'year'],
      dates: {
        start: dayjs(today()).subtract(1, 'month').format('YYYY-MM-DD'),
        end: today()
      },
      month: null,
      quarter: null,
      year: null
    }
  },
  watch: {
    rangeChoice() {
      if (this.hasYear && this.year === null) {
        this.year = this.yearChoices[0].value
      }
    },
    range() {
      if (this.valid) {
        this.$emit('change', this.range)
      }
    }
  },
  computed: {
    rangeChoices() {
      return this.choices.map(c => {
        return {
          value: c,
          text: this.makeTitle(c)
        }
      })
    },
    yearChoices() {
      let year = new Date().getFullYear()
      const choices = []
      while (year >= 2021) {
        let choice = year
        if (this.rangeChoice === 'year' || this.rangeChoice === 'quarter') {
          const text = this.rangeChoices === 'year' ? `Apr ${year - 2000} - Mar ${year - 1999}` : `${year} - ${year + 1}`
          choice = {
            text,
            value: year
          }
        }
        choices.push(choice)
        year--
      }
      return choices
    },
    monthChoices() {
      return dayjs.en.months.map((m, i) => {
        return {
          text: m,
          value: `${i + 1}`.padStart(2, '0')
        }
      })
    },
    quarterChoices() {
      return [
        { text: `Q1 Apr - Jun ${this.year - 2000}`, value: 4 },
        { text: `Q2 Jul - Sep ${this.year - 2000}`, value: 7 },
        { text: `Q3 Oct - Dec ${this.year - 2000}`, value: 10 },
        { text: `Q4 Jan - Mar ${this.year - 1999}`, value: 13 }
      ]
    },
    hasYear() {
      return ['month', 'quarter', 'year'].includes(this.rangeChoice)
    },
    range() {
      if (this.rangeChoice === 'all') {
        return { start: null, end: null }
      }
      let start, end
      if (this.rangeChoice === 'two-dates') {
        start = dayjs(this.dates.start).startOf('day')
        end = dayjs(this.dates.end).endOf('day')
      } else if (this.rangeChoice === 'month') {
        start = dayjs(`${this.year}-${this.month}-01`)
        end = start.endOf('month')
      } else if (this.rangeChoice === 'quarter') {
        if (this.quarter > 12) {
          start = dayjs(`${this.year + 1}-${this.quarter - 12}-01`)
        } else {
          start = dayjs(`${this.year}-${this.quarter}-01`)
        }
        end = start.add(2, 'months').endOf('month')
      } else if (this.rangeChoice === 'year') {
        start = dayjs(`${this.year}-04-01`)
        end = start.add(1, 'year')
      }
      return {
        start: start.format('YYYY-MM-DD HH:mm:ss'),
        end: end.format('YYYY-MM-DD HH:mm:ss')
      }
    },
    valid() {
      if (this.hasYear && this.year === null) return false
      if (this.rangeChoice === 'month' && this.month === null) return false
      if (this.rangeChoice === 'quarter' && this.quarter === null) return false
      return true
    }
  }
}
</script>

<style lang="scss">
div.date-range {
  display: flex;
  .v-text-field {
    max-width: 125px;
    margin: 4px 5px;

    &.quarter {
      max-width: 170px;
    }

    &.year {
      max-width: 150px;
    }
  }
}
</style>
